* {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
html,
body,
#root {
  height: 100%;
  margin: 0;
}
.views-block, #root {
  align-items: stretch;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  box-sizing: border-box;
  color: inherit;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-family: inherit;
  font-size: inherit;
  hyphens: auto;
  line-height: inherit;
  margin: 0;
  outline: 0;
  overflow-wrap: break-word;
  padding: 0;
  position: relative;
  text-align: left;
  text-decoration: none;
  white-space: normal;
  word-wrap: break-word;
}
.views-text {
  box-sizing: border-box;
  hyphens: auto;
  outline: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.views-capture {
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 0;
  border: 0;
  hyphens: auto;
  outline: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.views-capture::-moz-focus-inner {
  border: 0;
  margin: 0;
  padding: 0;
}
/* remove number arrows */
.views-capture[type='number']::-webkit-outer-spin-button,
.views-capture[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
